
(function(document, window, $) {
    'use strict';

    var Site = window.Site;

    $(document).ready(function($) {
        Site.run();

        var makeId = function(){
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for( var i=0; i < 10; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }

        var  initTinymce = function () {

            $('.tinymce').each(function(){

                var cssClass = makeId();
                $(this).addClass(cssClass).removeClass('tinymce');
                $(this).attr('id', cssClass);
                tinymce.dom.Event.domLoaded = true;

                tinymce.init({
                    editor_selector: cssClass,
                    height: 250,
                    plugins: "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table contextmenu paste",
                    toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    theme_advanced_toolbar_location: "top",
                    theme_advanced_toolbar_align: "left",
                    theme_advanced_statusbar_location: "bottom",
                    theme_advanced_resizing: true,
                    convert_urls: false,
                    // file_browser_callback: elFinderBrowser,
                    mode: "textareas",
                    theme: 'modern',
                    file_browser_callback : elFinderBrowser
                });

//                tinymce.EditorManager.execCommand('mceAddEditor', true, '.'+cssClass);
            });
        };

        initTinymce();

        $(window).on('initTinymce', function(){
            initTinymce()
        });

    });

        window.elFinderBrowser = function(field_name, url, type, win) {
            tinymce.activeEditor.windowManager.open({
                file: $('#elfinderpath').attr('data-url'),// use an absolute path!
                title: 'Media',
                width: 900,
                height: 450,
                resizable: 'yes'
        }, {
            setUrl: function (url) {
                win.document.getElementById(field_name).value = url;
            }
        });
        return false;
    }


})(document, window, jQuery);

