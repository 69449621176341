(function(document, window, $) {
    'use strict';

    window.AppCalendar = App.extend({
        handleFullcalendar: function() {

            var my_options = {
                header: {
                    left: 'today',
                    center: 'prev,title,next',
                    right: 'month,agendaWeek,agendaDay'

                },
                firstDay: 1,
               // defaultDate: '2015-10-12',
                selectable: true,
                selectHelper: true,
                select: function() {
                 //   $('#addNewEvent').modal('show');
                },
                editable: true,
                eventLimit: true,
                windowResize: function(view) {
                    var width = $(window).outerWidth();
                    var options = $.extend({}, my_options);
                    //options.events = view.calendar.getEventCache();
                    var aspectRatio = width < 667 ? 0.5 : 1.35;

                    $('#calendar').fullCalendar('option', 'aspectRatio', aspectRatio);
                //    $('#calendar').fullCalendar('destroy');
                 //   $('#calendar').fullCalendar(options);
                },
                eventDrop: function(event, delta, revertFunc) {
                   AppCalendar.updateCalendar(event, delta, 'start');
                },

                eventResize: function(event, delta, revertFunc) {
                    AppCalendar.updateCalendar(event, delta, 'end');
                },

                dayClick: function(date, jsEvent, view) {
                    console.log(view.name);
                    if (view.name === "month") {
                        $('#calendar').fullCalendar('gotoDate', date);
                        $('#calendar').fullCalendar('changeView', 'agendaDay');
                    }
                },

                eventClick: function(event) {

                    $.slidePanel.show({
                        url: event.ajaxurl,
                        settings: {
                            cache: false
                        }
                    }, window.PanelOptions);

                    //var color = event.backgroundColor ? event.backgroundColor : $.colors('blue', 600);
                    //$('#editEname').val(event.title);
                    //
                    //if (event.start) {
                    //    $('#editStarts').datepicker('update', event.start._d);
                    //} else {
                    //    $('#editStarts').datepicker('update', '');
                    //}
                    //if (event.end) {
                    //    $('#editEnds').datepicker('update', event.end._d);
                    //} else {
                    //    $('#editEnds').datepicker('update', '');
                    //}
                    //
                    //$('#editColor [type=radio]').each(function() {
                    //    var $this = $(this),
                    //        value = $this.data('color').split('|'),
                    //        value = $.colors(value[0], value[1]);
                    //    if (value === color) {
                    //        $this.prop('checked', true);
                    //    } else {
                    //        $this.prop('checked', false);
                    //    }
                    //});
                    //$('#editColor [value=' + event.backgroundColor + ']').prop('checked', true);
                    //
                    //$('#editNewEvent').modal('show').one('hidden.bs.modal', function(e) {
                    //    event.title = $('#editEname').val();
                    //
                    //    var color = $('#editColor [type=radio]:checked').data('color').split('|');
                    //    color = $.colors(color[0], color[1]);
                    //    event.backgroundColor = color;
                    //    event.borderColor = color;
                    //
                    //    event.start = new Date($('#editStarts').data('datepicker').getDate());
                    //    event.end = new Date($('#editEnds').data('datepicker').getDate());
                    //    $('#calendar').fullCalendar('updateEvent', event);
                    //})
                },
                eventDragStart: function() {
                   // $.site.actionBtn.expand();
                },
                eventDragStop: function() {
                   // $.site.actionBtn.shrink();
                },
                //events: my_events,

                events: $('#calendar').attr('data-feed'),
                droppable: true
            };

            var _options;
            var my_options_mobile = $.extend({}, my_options);

            my_options_mobile.aspectRatio = 0.5;
            _options = $(window).outerWidth() < 667 ? my_options_mobile : my_options;

            $('#editNewEvent').modal();
            $('#calendar').fullCalendar(_options);
        },


        updateCalendar: function (event, delta, type){
            var updateUrl = $('#calendar').attr('data-update');
            $.ajax({
                url: updateUrl,
                type: "POST",
                dataType: "json",
                data: ({
                    id: event.id,
                    daydelta: delta.days(),
                    hourdelta: delta.hours(),
                    type: type

                }),
                success: function(data, textStatus) {
                    if (!data)
                    {
                        // revertFunc();
                        return;
                    }
                    //calendar.fullCalendar('updateEvent', event);
                },
                error: function() {
                    //revertFunc();
                }
            });
        },
        handleAction: function() {
           // var actionBtn = $('.site-action').actionBtn().data('actionBtn');
        },

        handleEventList: function() {
            $('#addNewEventBtn').on('click', function() {
                $('#addNewEvent').modal('show');
            });

            $('.calendar-list .calendar-event').each(function() {
                var $this = $(this),
                    color = $this.data('color').split('-');
                $this.data('event', {
                    title: $this.data('title'),
                    stick: $this.data('stick'),
                    backgroundColor: $.colors(color[0], color[1]),
                    borderColor: $.colors(color[0], color[1])
                });
                $this.draggable({
                    zIndex: 999,
                    revert: true,
                    revertDuration: 0,
                    helper: function() {
                        return '<a class="fc-day-grid-event fc-event fc-start fc-end" style="background-color:' + $.colors(color[0], color[1]) + ';border-color:' + $.colors(color[0], color[1]) + '">' +
                            '<div class="fc-content">' +
                            '<span class="fc-title">' + $this.data('title') + '</span>' +
                            '</div>' +
                            '</a>';
                    }
                });
            });
        },

        refreshCalendar: function() {
                $('#calendar').fullCalendar( 'refetchEvents' );
        },

        handleListenForEvents: function() {
            var that = this;
            $('body').on('calendar:refresh', function( event ){
               that.refreshCalendar();
            });
        },

        run: function(next) {

            var calendar = $('#calendar');

            if (!calendar.length) { next(); return;}

            $('#addNewCalendarForm').modal({
                show: false
            });


            //this.handleEventList();
            this.handleFullcalendar();
            this.handleListenForEvents();
            //this.handleAction();

            next();
        }
    });

    $(document).ready(function() {
        AppCalendar.run();
    });
})(document, window, jQuery);
