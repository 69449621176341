(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();



      /*
       * DROPZONE
       */
      if ($(".mdropzone").length)
          $(".mdropzone").each(addDropzoneEvent);

      function addDropzoneEvent(){

          var concat='?';
          //TODO change the way we get-send token
          var field=$(this).attr('data-field');
          var customformat = $(this).attr('data-customformat');
          var url=$(this).attr('data-url')+concat+'component='+$(this).attr('data-component')+'&customformat='+customformat+'&field='+field+'&_token='+$('meta[name="csrf-token"]').attr('content');
          var id=$(this).attr('id');
          var preview=$(this).attr('data-preview');
          var mode=$(this).attr('data-mode');


          var customCall = $(this).attr('data-custom-call');
          var dz=$('#'+id).dropzone({
              url: url,
              autoProcessQueue: true,
              uploadMultiple: true,
              parallelUploads:1,
              maxFiles: 100,
              addRemoveLinks:true,
              dictDefaultMessage: '<i class="icon ti-upload"></i> DROP FILES HERE TO UPLOAD',
              init: function() {

                  var mdropzone=this;

                  this.on("success",function(file,responseText){
                      //for single image
                      if (mode=='single'){
                          if (preview!=undefined)
                              $('#'+preview).attr('src',responseText['filename']);
                            $('#'+field).val(responseText['filename']);

                          if (responseText['value']!=undefined)
                              $('#'+field).val(responseText['value']);
                      }else{
                          //for multiple images
                          if (customCall != undefined) {
                              window[customCall](responseText['filename']);
                          } else {
                              //addMultiPreviewFile(preview, responseText['filename'], field);
                              addMultiPreviewTemplate(preview, responseText['template']);
                          }
                      }

                      $(file.previewTemplate).remove();

                  });

                  //this.on("addedfile", function(file) {
                  //
                  //});
                  //
                  //this.on("removedfile", function(file) {
                  //
                  //});
                  //
                  //this.on("processingfile", function(file) {
                  //
                  //});

                  this.on("error", function(file) {
                      inMessageError("Error uploading file");
                      $(file.previewTemplate).remove();
                  });


              }

          });
      }


  });

    window.addMultiPreviewTemplate = function(target, template){
        $('#'+target).append(template);
        $('.sortable').sortable();
    };

    window.addMultiPreviewFile =  function(target, filename, field){
        console.log(target+' '+filename+' '+field);
         var template = '<div> <input type="hidden" name="'+field+'" value="'+filename+'"/>';
         template += '<img src="'+filename+'" class="img-responsive"/>';
         template +='</div>';

        $('#'+target).append(template);
    };

    Dropzone.autoDiscover = false;

})(document, window, jQuery);
