(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();


      //we hide the multi delete
      $('[data-type="deletemulti"]').each(function(){
          $(this).slideUp(0);
          var target = $(this).attr('data-target');
            var self = this;

          $(target).on('selected:on', function(){
              $(self).slideDown();
          });

          $(target).on('selected:off', function(){
              $(self).slideUp();
          });

      });


      $(document).on('click','[data-type="deletetarget"]', function(e){
          e.preventDefault();
          var target = $(this).attr('data-deletetarget');
          $(target).remove();
      });


      //delete single
      $(document).on('click','[data-type="deletesingle"]', function(e){
          e.preventDefault();

          var url = $(this).attr('data-href');

          var that = $(this);

          //alertify.confirm( "Delete ?", function () {
          //    $(that).closest('.model-item').fadeOut();
          //    sendPost('#', $(that).closest('.model-item'), '', unfadeElement);
          //});




          bootbox.confirm("Are you sure you want to delete?", function(result) {
              if (result) {

                  var mode = $(that).attr('data-mode');
                  if (typeof mode !== 'undefined'){
                      if (mode == 'panel') {
                          $('.slidePanel-close').trigger('click');
                      }
                  }

                  $(this).addClass('disabled');

                  if (url.indexOf('#') == 0){ //we just want to submit the form with the method delete
                    $(url+' input[name=_method]').val('DELETE');
                    $(url).submit();
                  }else {
                      $.post(url, {'_method': 'delete'})
                          .done(function (data) {
                              var eventAfter = $(that).attr("data-eventafter");
                              if (typeof eventAfter !== 'undefined')  $('body').trigger(eventAfter);

                              var callbackAfter = $(that).attr("data-callbackafter");
                              var fn = window[callbackAfter];
                              console.log(typeof fn);
                              if (typeof fn === "function") {
                                  fn();
                              }
                          })
                          .fail(function () {
                              inMessage('Bla bla bla', {message: 'Bla bla erased', type: 'error'});
                          })
                          .always(function () {

                          });
                  }
              }
          });

      });


    //delete multi
      $(document).on('click','[data-type="deletemulti"]', function(e){
          e.preventDefault();

          var target = $(this).attr('data-target');
          //var url = $(this).attr('data-url');
          var url = $(this).attr('data-href');

          var $selectedIds = getSelectedItemsValues(target);

          if ($selectedIds.length == 0){
              inErrorMessage('Select items please');
              return;
          }


          var filterSelector = $(target).attr('data-filter');

          var filter = $(target).attr('data-filter');

          var self = this;

          bootbox.confirm("Are you sure you want to delete?", function(result) {
              if (result) {
                  $(this).addClass('disabled');
                  $.post(url, {'ids': $selectedIds, '_method': 'delete'})
                      .done(function (data) {

                          $(filter).trigger('filter:refresh');
                      })
                      .fail(function () {
                          inMessage('Bla bla bla', {message: 'Bla bla erased', type: 'error'});
                      })
                      .always(function () {
                          $(self).removeClass('disabled');
                      });
              }
          });

          /*
          bootbox.confirm("Are you sure?", function(result) {
              if (result) {
                  var filter = $(target).attr('data-filter');
                  $(filterSelector).trigger('filter:refresh');
              }

          });
            */

      });



      function unfadeElement(element){
          $(element).fadeIn();
      }


      function sendPost(url, callbackOption, callbackOk, callbackFail ){

          inMessage('Bla bla bla', {message: 'Bla bla erased', type: 'error'});
          if (typeof callbackOk === "function") {
              callbackOk(callbackOption);
          }
          if (typeof callbackFail === "function") {
              callbackFail(callbackOption);
          }
      }

      //filter button



      $(document).on('click','[data-type="filter-cleaner"]', function(e){
          e.preventDefault();
          var target = $(this).closest('form');
          $(target).trigger('filter:nofiltering');
          clear_form_elements(target);
          $(target).trigger('filter:clear');
          $(target).trigger('filter:filtering');
          $(target).trigger('filter:refresh');

      });



      //
      //$(document).on('click','[data-type="filterbutton"]', function(e){
      //    e.preventDefault();
      //    var target = $(this).attr('data-href');
      //
      //    ($(target).hasClass('in')) ? $(this).removeClass('active') : $(this).addClass('active');
      //
      //    $(target).collapse('toggle');
      //});


      $(document).on('click','[data-type="saveform"]', function(e){

          e.preventDefault();

         var target = $(this).attr('data-href');
          $(target).submit();

      });


      $(document).on('click','[data-type="gotourl"]', function(e){

          e.preventDefault();

          var url = $(this).attr('href');
          window.location = url;

      });

      $(document).on('click','[data-type="filterbutton"]', function(e){
          e.preventDefault();
          var target = $(this).attr('data-href');

          ($(target).hasClass('in')) ? $(this).removeClass('active') : $(this).addClass('active');

          $(target).collapse('toggle');
      });

      $('[data-type="filterbutton"]').each(function(){
          var target = $(this).attr('data-href');
          var isFiltered = $(target).find('form').first().attr('data-filtered');


          if (parseInt(isFiltered)) {
              $(this).addClass('active');
              $(this).trigger('click');
          }
      });





  });



})(document, window, jQuery);
