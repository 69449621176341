(function(document, window, $) {
    'use strict';

    var Site = window.Site;

    $(document).ready(function($) {
        Site.run();
    });


    window.addLoading = function(target) {
        console.log('addLoading');
        $(target).html('<div class="height-10 text-center"><div class="loader loader-default"></div></div>');
    };

    window.getSelectedItems = function(target) {
        var $selectable = $(target+' [data-selectable]');
        var $selected = $selectable.asSelectable('getSelected');
        return $selected;
    };

    window.getSelectedItemsValues = function(target) {
        var items = getSelectedItems(target);
        var ret = [];
        $(items).each(function(){
            ret.push($(this).val());
        })

        return ret;
    }

    window.clear_form_elements = function(ele) {



        $(ele).find(':input').each(function() {
            switch(this.type) {
                case 'password':
                case 'select-multiple':
                case 'select-one':
                case 'text':
                case 'textarea':
                    $(this).val('');
                    break;
                case 'checkbox':
                case 'radio':
                    this.checked = false;
            }

            $(this).trigger('change');
        });


    }

    window.initSlidePanel = function($target){
        var target = "#filter-result";
        $('[data-type="selectedcount"]').each(function(){
            //var target = $(this).attr('data-target');
            var self = this;

            $(target).on('selected:changed', function(){
                $(self).text(getSelectedItemsValues(target).length);
                var mytarget = $(self).attr('data-target');
                $(mytarget).val(getSelectedItemsValues(target));
            });
        });

        $(target).trigger('selected:changed');


        $('[data-type="filteredcount"]').each(function(){
            var self = this;
            $(target).on('filter:done', function(){
                $(self).text($(target).find('.filtercount').first().text());
            });
        });

        $(target).trigger('filter:done');




    }



})(document, window, jQuery);
